<template>
  <section class="content">
    <div class="text" v-if="success == true">
      <h1>Successful</h1>
      <p>You can close this browser tab.</p>
    </div>
    <div class="text" v-if="failure == true">
      <h1>Error</h1>
    </div>
  </section>
</template>

<script>
import api from "@/services/api"

export default {
  data() {
    return {
      success: false,
      failure: false
    }
  },
  created() {
    if (this.$route.query.k == "22dJ9sh") {
      api
        .callFunctions()
        .then(() => {
          this.success = true
        })
        .catch((err) => {
          this.failure = true
          console.log(err)
        })
    }
  }
}
</script>
